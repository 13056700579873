import styled from '@emotion/styled';
import {MEDIA_QUERIES} from "../../shared/constants/media";

const StyledButton = styled.button(props => ({
    borderRadius: '76px',
    padding: '0 65px',
    fontFamily: 'Roboto, sans-serif',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '42px',
    textAlign: 'center',
    letterSpacing: '0.36px',
    textTransform: 'uppercase',
    cursor: 'pointer',
    svg: {
        verticalAlign: 'text-bottom',
        marginLeft: '8px',
    },
    [MEDIA_QUERIES.w.ltMd]: {
        fontSize: '18px',
        padding: '0 25px',
    },
    ...(props.type === "primary" && {
        backgroundColor: 'rgba(217, 217, 217, 0.1)',
        color: '#58CACD',
        border: '2px solid #58CACD',
        '&:hover': {
            backgroundColor: 'rgba(88, 202, 205, 0.1)',
            color: '#459FA1',
            border: '2px solid rgba(88, 202, 205, 0.6)',
        },
        '&:focus': {
            backgroundColor: 'rgba(88, 202, 205, 0.1)',
            color: '#459FA1',
            border: '2px solid rgba(88, 202, 205, 0.6)',
        },
    }),
    ...(props.type === "secondary" && {
        backgroundColor: '#58CACD',
        color: '#ffffff',
        border: '2px solid transparent',
        '&:hover': {
            backgroundColor: 'rgba(88, 202, 205, 0.6)',
        },
        '&:focus': {
            backgroundColor: '#1AACAF',
        },
    }),
}))

export {StyledButton};
