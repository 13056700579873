import styled from '@emotion/styled';

const StyledFrame = styled.div(props => ({
    background: '#F9FDFF',
    border: '1px solid rgba(208, 239, 255, 0.62)',
    boxSizing: 'border-box',
    boxShadow: '-32px -13px 14px 1px rgba(255, 255, 255, 0.82),' +
                '9px 11px 21px -12px #D1E4ED,' +
                'inset -32px -13px 14px 1px rgba(255, 255, 255, 0.82),' +
                'inset 9px 11px 21px -12px #D1E4ED',
    borderRadius: '19px',
}))

export {StyledFrame};
