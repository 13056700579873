import styled from '@emotion/styled';
import {MEDIA_QUERIES} from "../../shared/constants/media";
import instruction from "../../images/instruction_.webp";
import instructionMobile from "../../images/instruction_mobile.webp";

const StyledInstructionPage = styled.div(props => ({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    div: {
        width: '82%',
        height: '86%',
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        marginTop: '15px',
        [MEDIA_QUERIES.w.ltMd]: {
            width: '300px',
        },
        div: {
            width: '99%',
            height: '100%',
            margin: '0 auto',
            borderRadius: '10px',
            backgroundImage: `url(${instruction})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            [MEDIA_QUERIES.w.ltMd]: {
                width: '300px',
                backgroundImage: `url(${instructionMobile})`,
            },
        },
    },
    button: {
        width: 'fit-content',
        marginTop: '35px',
    }
}))

export {StyledInstructionPage};
