import React from "react";
import { useParams, useNavigate } from "react-router-dom";

import {MainLayout} from "../../components/Layout/MainLayout";
import {Frame} from "../../components/Frame/Frame";
import {Button} from "../../components/Button/Button";

import {StyledActivatePage} from "./styled";
import {ReactComponent as Shirt} from "../../images/t-shirt.svg";
import {ReactComponent as ActiveBtn} from "../../images/ActivateBtn.svg";
import {ReactComponent as ShopSimilar} from "../../images/shopSimilar.svg";
import {ReactComponent as Arrow} from "../../images/Arrow.svg";

const ActivateInstructionPage = () => {
    let navigate = useNavigate();
    let {storeId} = useParams();

    const handleEvent = () => {
        navigate(`/dashboard/${storeId}/theme-instruction`);
    }

    return (
        <MainLayout>
            <StyledActivatePage>
                <div>
                    <Frame>
                        <p>Push Activate Button</p>
                        <ActiveBtn/>
                        <p></p>
                    </Frame>
                    <Arrow/>
                    <Frame>
                        <p>Online Store</p>
                        <Frame>
                            <Shirt/>
                            <ShopSimilar/>
                        </Frame>
                    </Frame>
                </div>
                <Button type='secondary' onClick={handleEvent}>activate</Button>
            </StyledActivatePage>
        </MainLayout>
    )
}

export {ActivateInstructionPage};
