import {useState} from "react";
import {Button} from "../Button/Button";
import {gql, useMutation} from "@apollo/client";

const SETUP_BILLING = gql`
    mutation {
        setupBilling {
            url
            }
        }
`;

const FreeTrialButton = () => {
    const [trial, setTrial] = useState('Free trial')
    const [setupBilling, {loading, error}] = useMutation(SETUP_BILLING, {
        onCompleted: (data) => {
            if (data.setupBilling.url) {
                window.open(data.setupBilling.url, '_self');
            } else {
                window.location.reload()
            }
        }
    });

    if (loading) return <Button type='secondary'>Loading...</Button>
    if (error) return `Submission error! ${error.message}`;

    const handleSetupBilling = () => {
        setupBilling();
        setTrial('Loading...');
    }

    return (
        <Button type='secondary' onClick={handleSetupBilling} arrow={true}>
            {trial}
        </Button>
    )
}

export {FreeTrialButton};
