import styled from '@emotion/styled';
import {MEDIA_QUERIES} from "../../shared/constants/media";

const StyledDeactivatePage = styled.div(props => ({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
}));

export {StyledDeactivatePage};

const FrameContainer = styled.div(props => ({
    width: '100%',
    height: '65%',
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    marginTop: '35px',
    [MEDIA_QUERIES.w.ltMd]: {
        flexDirection: 'column',
        marginTop: '0',
        height: '75%',
    },
    div: {
        width: '44%',
        flexDirection: 'column',
        justifyContent: 'center',
        [MEDIA_QUERIES.w.ltMd]: {
            width: '70%',
            marginTop: '30px',
            marginBottom: '30px'
        },
        h2: {
            fontFamily: 'Roboto, sans-serif',
            fontWeight: 'bold',
            fontSize: '48px',
            lineHeight: '60px',
            textAlign: 'center',
            color: '#6EBFC1',
            mixBlendMode: 'normal',
            backdropFilter: 'blur(37px)',
        },
        p: {
            fontFamily: 'Roboto, sans-serif',
            fontWeight: '300',
            fontSize: '16px',
            lineHeight: '40px',
            textAlign: 'center',
            color: '#000000',
            mixBlendMode: 'normal',
        },
    },
}));

export {FrameContainer};

const BtnContainer = styled.div(props => ({
    width: '100%',
    display: 'flex',
    justifyContent: 'center',

    button: {
        margin: '20px 10px 0 10px',
    },
    [MEDIA_QUERIES.w.ltMd]: {
        flexDirection: 'column',
    },
}));

export {BtnContainer};
