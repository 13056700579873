import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import {gql, useQuery} from "@apollo/client";

import {MainLayout} from "../../components/Layout/MainLayout";
import {Frame} from "../../components/Frame/Frame";
import {Button} from "../../components/Button/Button";

import {StyledInstructionPage} from "./styled";

const CURRENT_STORE = gql`
    {
        store {
          activateUrl
        }
    }
`;

const ThemeInstructionPage = () => {
    const {error, loading, data} = useQuery(CURRENT_STORE);

    let navigate = useNavigate();
    let {storeId} = useParams();

    if (loading) {
        return <div className="loader">Loading...</div>
    }

    if (error) {
        return 'Error'
    }

    const handleActivated = () => {
        localStorage.setItem(`lookyActivated-${storeId}`, JSON.stringify(true));
        navigate(`/dashboard/${storeId}`);
        window.open(data.store.activateUrl, '_blank').focus();
    }

    return (
        <MainLayout>
            <StyledInstructionPage>
                <Frame>
                    <div />
                </Frame>
                <Button type='secondary' onClick={handleActivated}>go to the theme editor</Button>
            </StyledInstructionPage>
        </MainLayout>
    )
}

export {ThemeInstructionPage};
