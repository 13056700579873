import React from "react";
import {StyledButton} from "./styled";
import {ReactComponent as ArrowRight} from "../../images/arrow-right.svg";


const Button = ({children, onClick, type, arrow}) => {
    return (
        <StyledButton onClick={onClick} type={type}>
            {children}
            {arrow && <ArrowRight />}
        </StyledButton>
    )
}

export {Button};