import React from "react";
import {StyledFrame} from "./styled";

const Frame = ({children}) => {
    return (
        <StyledFrame>
            {children}
        </StyledFrame>
    )
}

export {Frame};
