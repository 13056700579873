import styled from '@emotion/styled';
import {MEDIA_QUERIES} from "../../shared/constants/media";

const StyledMainLayout = styled.div(props => ({
    height: '100%',
    backgroundColor: '#F9FDFF',
    '.logo': {
        height: '8%',
        svg: {
            marginTop: '35px',
            marginLeft: '130px',
            [MEDIA_QUERIES.w.ltMd]: {
                marginLeft: '6px'
            },
        },
    },
    '.container': {
        margin: 'auto 140px',
        height: '80%',
        [MEDIA_QUERIES.w.ltMd]: {
            margin: 'auto 10px',
        },
    }
}))

export {StyledMainLayout};
