import React from "react";
import {useNavigate, useParams} from "react-router-dom";
import {gql, useMutation} from "@apollo/client";

import {MainLayout} from "../../components/Layout/MainLayout";
import {Frame} from "../../components/Frame/Frame";
import {Button} from "../../components/Button/Button";

import {StyledDeactivatePage, BtnContainer, FrameContainer} from "./styled";

const SETUP_FIX_BUTTON = gql`
    mutation {
        requestFix {
            ok
            }
        }
`;

const HomePage = ({views, clicks}) => {
    let navigate = useNavigate();
    let {storeId} = useParams();
    const [setupFixButton, {error}] = useMutation(SETUP_FIX_BUTTON, {
        onCompleted: () => {
            window.alert('Fix request sent, we will fix the app and contact you by email in 24 hours');
        }
    })

    if (error) return `Submission error! ${error.message}`;

    const handleFixButton = () => {
        setupFixButton();
    }
    const handleActivateEvent = () => {
        navigate(`/dashboard/${storeId}/theme-instruction`);
    }

    return (<MainLayout>
        <StyledDeactivatePage>
            <FrameContainer>
                <Frame>
                    <h2>{views}</h2>
                    <p>Views generated by <b>Looky</b></p>
                </Frame>
                <Frame>
                    <h2>{clicks}</h2>
                    <p>Clicks generated by <b>Looky</b></p>
                </Frame>
            </FrameContainer>
            <BtnContainer>
                <Button onClick={handleActivateEvent} type="primary">activate / deactivate</Button>
                <Button onClick={handleFixButton} type="secondary">fix the app in 24 hours</Button>
            </BtnContainer>
        </StyledDeactivatePage>
    </MainLayout>
    )
}

export {HomePage};
