export const BREAKPOINT_RESOLUTIONS = {
    xs: 576,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1280,
    xxl: 1400,
    xxxl: 1600
};

const queryBase = `@media screen and`;
const ltQuery = (resolution, orientation) =>
    `${queryBase} (max-${orientation}: ${resolution - 1}px)`;
const gteQuery = (resolution, orientation) =>
    `${queryBase} (min-${orientation}: ${resolution}px)`;
const btwQuery = (res1, res2, orientation) =>
    `${queryBase} (min-${orientation}: ${res1}px) and (max-${orientation}: ${res2 -
    1}px)`;

export const ltQueryW = resolution => ltQuery(resolution, "width");
export const gteQueryW = resolution => gteQuery(resolution, "width");
export const btwQueryW = (res1, res2) => btwQuery(res1, res2, "width");
export const ltQueryH = resolution => ltQuery(resolution, "height");
export const gteQueryH = resolution => gteQuery(resolution, "height");
export const btwQueryH = (res1, res2) => btwQuery(res1, res2, "height");

export const MEDIA_QUERIES = {
    w: {
        ltSm: ltQueryW(BREAKPOINT_RESOLUTIONS.sm),
        ltMd: ltQueryW(BREAKPOINT_RESOLUTIONS.md),
        ltLg: ltQueryW(BREAKPOINT_RESOLUTIONS.lg),
        ltXl: ltQueryW(BREAKPOINT_RESOLUTIONS.xl),
        ltXxl: ltQueryW(BREAKPOINT_RESOLUTIONS.xxl),
        gteSm: gteQueryW(BREAKPOINT_RESOLUTIONS.sm),
        gteMd: gteQueryW(BREAKPOINT_RESOLUTIONS.md),
        gteLg: gteQueryW(BREAKPOINT_RESOLUTIONS.lg),
        gteXl: gteQueryW(BREAKPOINT_RESOLUTIONS.xl),
        gteXxl: gteQueryW(BREAKPOINT_RESOLUTIONS.xxl),
        gteXxxl: gteQueryW(BREAKPOINT_RESOLUTIONS.xxxl),
        btwSmMd: btwQueryW(BREAKPOINT_RESOLUTIONS.sm, BREAKPOINT_RESOLUTIONS.md),
        btwMdLg: btwQueryW(BREAKPOINT_RESOLUTIONS.md, BREAKPOINT_RESOLUTIONS.lg),
        btwMdXl: btwQueryW(BREAKPOINT_RESOLUTIONS.md, BREAKPOINT_RESOLUTIONS.xl),
        btwLgXl: btwQueryW(BREAKPOINT_RESOLUTIONS.lg, BREAKPOINT_RESOLUTIONS.xl),
        btwXlXxl: btwQueryW(BREAKPOINT_RESOLUTIONS.xl, BREAKPOINT_RESOLUTIONS.xxl)
    },
    h: {
        ltSm: ltQueryH(BREAKPOINT_RESOLUTIONS.sm),
        ltMd: ltQueryH(BREAKPOINT_RESOLUTIONS.md),
        ltLg: ltQueryH(BREAKPOINT_RESOLUTIONS.lg),
        ltXl: ltQueryH(BREAKPOINT_RESOLUTIONS.xl),
        ltXxl: ltQueryH(BREAKPOINT_RESOLUTIONS.xxl),
        gteSm: gteQueryH(BREAKPOINT_RESOLUTIONS.sm),
        gteMd: gteQueryH(BREAKPOINT_RESOLUTIONS.md),
        gteLg: gteQueryH(BREAKPOINT_RESOLUTIONS.lg),
        gteXl: gteQueryH(BREAKPOINT_RESOLUTIONS.xl),
        gteXxl: gteQueryH(BREAKPOINT_RESOLUTIONS.xxl),
        btwSmMd: btwQueryH(BREAKPOINT_RESOLUTIONS.sm, BREAKPOINT_RESOLUTIONS.md),
        btwMdLg: btwQueryH(BREAKPOINT_RESOLUTIONS.md, BREAKPOINT_RESOLUTIONS.lg),
        btwMdXl: btwQueryH(BREAKPOINT_RESOLUTIONS.md, BREAKPOINT_RESOLUTIONS.xl),
        btwLgXl: btwQueryH(BREAKPOINT_RESOLUTIONS.lg, BREAKPOINT_RESOLUTIONS.xl),
        btwXlXxl: btwQueryH(BREAKPOINT_RESOLUTIONS.xl, BREAKPOINT_RESOLUTIONS.xxl)
    }
};
