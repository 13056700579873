import React from "react";
import {gql, useQuery} from "@apollo/client";
import {Navigate, useParams} from "react-router-dom";

import {MainLayout} from "../../components/Layout/MainLayout";
import {FreeTrialButton} from "../../components/FreeTrialButton/FreeTrialButton";

import shoppingGirl from "../../images/shoppingGirl.svg";
import {StyledHomePage} from "./styled";
import {StyledBox} from "./styled";

const CURRENT_BILLING = gql`
    {
        store {
            billing
        }
    }
`;

const BillingPage = () => {
    let {storeId} = useParams();
    const {loading, error, data} = useQuery(CURRENT_BILLING);

    if (loading) return <div className="loader">Loading...</div>
    if (error) return 'Error'

    return (
        <>
            {data.store.billing && <Navigate to={`/dashboard/${storeId}`}/>}
            <MainLayout>
                <StyledHomePage>
                    <div className="invite">
                        <h2>
                            <span>Looky is an opportunity</span> for <br/>consumers to find and buy more
                        </h2>
                        <p>Improving sales for merchants by increasing<br/> the average order value (AOV)</p>
                    </div>
                    <img alt='girl with smartphone' src={shoppingGirl}/>
                    <div className="price-box">
                        <div>
                            <h5>7-day Free Trial</h5>
                            <h3>Pricing Guide</h3>
                        </div>
                        <div className="price-inf">
                            <div className="half-side list">
                                <h4>Each package includes:</h4>
                                <ul>
                                    <li> Similar search button for all products</li>
                                    <li> A new type of upselling</li>
                                    <li> Automatic recommendations using Artificial Intelligence</li>
                                    <li> Page with similar products</li>
                                    <li> Unique application and affordable price</li>
                                </ul>
                            </div>
                            <div className="half-side mom-box">
                                <StyledBox>
                                    <h4>Free</h4>
                                    <p><span>From 0 to 50</span> products in the store</p>
                                </StyledBox>
                                <StyledBox>
                                    <h4>$19/month</h4>
                                    <p><span>From 51 to 200</span> products in the store</p>
                                </StyledBox>
                                <StyledBox>
                                    <h4>$29/month</h4>
                                    <p><span>From 201 to 999</span> products in the store</p>
                                </StyledBox>
                                <StyledBox>
                                    <h4>$49/month</h4>
                                    <p><span>From 1000 and above</span> products in the store</p>
                                </StyledBox>
                            </div>
                        </div>
                    </div>
                    <FreeTrialButton/>
                </StyledHomePage>
            </MainLayout>
        </>
    )
}

export {BillingPage};
