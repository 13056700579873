import {BrowserRouter, Route, Routes,} from "react-router-dom";
import Auth from "../Auth";
import Dashboard from "../Dashboard";
import {BillingPage} from "../pages/BillingPage/BillingPage";
import {ActivateInstructionPage} from "../pages/ActivateInstructionPage/ActivateInstructionPage";
import {ThemeInstructionPage} from "../pages/ThemeInstructionPage/ThemeInstructionPage";
import {DashboardHome} from "../DashboardHome";
import {PrivacyPolicyPage} from "../pages/PrivacyPolicyPage/PrivacyPolicy";

const BaseRouter = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/auth" element={<Auth/>}/>
                <Route path="/dashboard/:storeId" element={<Dashboard/>}>
                    <Route index element={<DashboardHome/>}/>
                    <Route path="billing" element={<BillingPage/>}/>
                    <Route path="activate-instruction" element={<ActivateInstructionPage/>}/>
                    <Route path="theme-instruction" element={<ThemeInstructionPage/>}/>
                </Route>
                <Route path="/privacy-policy" element={<PrivacyPolicyPage/>}/>
            </Routes>
        </BrowserRouter>
    )
}

export {BaseRouter};
