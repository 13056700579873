import styled from '@emotion/styled';
import {MEDIA_QUERIES} from "../../shared/constants/media";

const StyledActivatePage = styled.div(props => ({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    div: {
        width: '100%',
        height: '80%',
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        marginTop: '10px',
        [MEDIA_QUERIES.w.ltMd]: {
            flexDirection: 'column',
            height: '85%',
            margin: '10px auto',
        },
        div: {
            width: '40%',
            flexDirection: 'column',
            justifyContent: 'space-between',
            boxSizing: 'border-box',
            padding: '30px',
            [MEDIA_QUERIES.w.ltMd]: {
                height: '45%',
                width: '75%',
                padding: '10px',
            },
            'svg': {
                width: '75%',
                [MEDIA_QUERIES.w.ltMd]: {
                    width: '100%',
                },
            },
            div: {
                width: '65%',
                padding: '10px',
                boxSizing: 'inherit',
                display: 'flex',
                justifyContent: 'space-evenly',
                height: '80%',
                [MEDIA_QUERIES.w.ltMd]: {
                    height: '74%',
                },
                'svg:nth-child(2)': {
                    width: '55%',
                    marginLeft: '110px',
                    [MEDIA_QUERIES.w.ltMd]: {
                        width: '70%',
                        marginLeft: '50px',
                        marginTop: '6px',
                    },
                },
            },
            p: {
                fontFamily: 'Roboto, sans-serif',
                fontWeight: 'bold',
                fontSize: '20px',
                lineHeight: '20px',
                textAlign: 'center',
                color: '#6EBFC1',
                backdropFilter: 'blur(37px)',
                [MEDIA_QUERIES.w.ltMd]: {
                    marginBottom: '5px',
                },
            },
        },
    },
    button: {
        width: 'fit-content',
        marginTop: '35px',
    },
}))

export {StyledActivatePage};
