import {useNavigate, useSearchParams} from "react-router-dom";
import {useEffect, useState} from "react";
import FingerprintJS from '@fingerprintjs/fingerprintjs'


function Auth() {
    let [searchParams] = useSearchParams();
    let navigate = useNavigate();
    const [failed, setFailed] = useState(false);

    let code = searchParams.get("code");

    useEffect(() => {
        async function authActivate() {
            const fp = await FingerprintJS.load();
            const fpResult = await fp.get()

            try {
                const response = await fetch(
                  `https://looky.apalapp.com/auth/activate?code=${code}&visitorId=${fpResult.visitorId}`,
                  {method: 'POST'}
                );
                const data = await response.json();

                const storeId = data['store_id'];
                window.localStorage.setItem(`token-${storeId}`, data['token']);
                navigate(`/dashboard/${storeId}`);
            } catch (err) {
                console.error(err);
                setFailed(true);
            }
        }

        authActivate();
    }, [navigate, code]);

    if (failed) {
        return 'Logging failed';
    }

    return 'Logging in...';
}

export default Auth;