import styled from '@emotion/styled';
import {MEDIA_QUERIES} from "../../shared/constants/media";

const StyledPrivacyPolicyPage = styled.div(props => ({
    div: {
        height: '600px',
        overflow:'auto',
        padding: '0 30px',
        [MEDIA_QUERIES.w.ltMd]: {
            height: '700px',
            padding: '0 25px',
        },
        h4: {
            textAlign: 'center',
        },
        table: {
            border: '1px solid black',
            borderCollapse: 'collapse',
            td: {
                border: '1px solid black',
                borderCollapse: 'collapse',
                padding: '5px',
            },
        }
    },
}))

export {StyledPrivacyPolicyPage};
