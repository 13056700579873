import React from "react";
import {StyledMainLayout} from "./styled";
import {ReactComponent as Logo} from "../../images/logoLooky.svg";

const MainLayout = ({children}) => {
    return (
        <StyledMainLayout>
            <div className='logo'>
                <Logo/>
            </div>
            <div className='container'>
                {children}
            </div>

        </StyledMainLayout>
    )
}

export {MainLayout};
