import React from "react";

import {MainLayout} from "../../components/Layout/MainLayout";
import {Frame} from "../../components/Frame/Frame";

import {StyledPrivacyPolicyPage} from "./styled";

const PrivacyPolicyPage = () => {
    return (
        <MainLayout>
            <StyledPrivacyPolicyPage>
                <Frame>
                    <h2>Privacy Policy</h2>
                    <p>“Company”, “Looky”, “we”, “us” and/or “our”) collects information about you, how we use and
                        share that information, and the privacy choices we offer. This policy applies to information
                        (including personally identifiable data (“Personal Data”)) we collect when you register, access
                        or use our website, applications, products, and services, or when you otherwise interact with us
                        (collectively, the “Services”). As used in this Privacy Policy, the term “Site” includes: all
                        websites and all devices or applications operated by Looky that collect personal information
                        from you; pages within each such website, device, or application, any equivalent, mirror,
                        replacement, substitute, or backup website, device, or application; and pages that are
                        associated with each such website, device, or application.
                    </p>
                    <p>Information received from Shopify. You can use our application only if you are a merchant of the
                        Shopify store. When you install our application through the Shopify app store we automatically
                        gain
                        access to your full name, address, e-mail address and cell phone number, date of birth, answers
                        to
                        customers surveys.
                    </p>
                    <h5>This Privacy Policy applies to your personal information when:</h5>
                    <ul>
                        <li> You use our services, visit our Site;</li>
                        <li> You contact us via our Site/email/phone/live chats/etc.;</li>
                        <li> You provide us with your personal information in any other way (e.g. through webinars, user
                            conferences, feedback sharing, etc.);
                        </li>
                        <li> We contact you about our new commercial offers, updates to our products, white papers,
                            newsletters, content, and events.
                        </li>
                    </ul>
                    <p>This Privacy Policy also explains your rights with respect to personal information we collect
                        about you and how we protect your personal information.</p>
                    <p> When you use the Application, we collect information about your use of the Application. For
                        example, we may record the frequency and scope of your use, action taken while using the
                        Application and the interactions you make with the Application.
                    </p>
                    <h4> Use of Personal Data</h4>
                    <p>
                        We use, save and analyze the information you provide about yourself and about your Third-Party
                        Sites (together “Data Processing”). Such Data Processing is necessary to perform our Services to
                        you, including our Terms of Service and our legitimate business interests, including:
                    </p>
                    <ul>
                        <li>to fulfill your requests for our products, programs and Services and process and analyze
                            your application,
                        </li>
                        <li>to respond to your inquiries about our Services,</li>
                        <li>to provide customer support,</li>
                        <li> to offer you other products, programs, or services that we believe may be of interest to
                            you,
                        </li>
                        <li>to verify the existence and condition of your accounts and conduct due diligence,</li>
                        <li>to fulfill legal requirements,</li>
                        <li>to protect against potentially prohibited or illegal activities,</li>
                        <li>to improve (including through data analytics), monitor and personalize the Services and
                            conduct our business and to provide targeted marketing and advertising,
                        </li>
                        <li>to measure the performance of and improve the Site or mobile application functionality;</li>
                        <li>to manage, administer and protect our information technology infrastructure and Site
                            security generally.
                        </li>
                    </ul>
                    <p>When you provide information to us you agree to give us your consent to use, collect, monitor
                        and process it according to the following Privacy Policy.
                    </p>
                    <p>Looky may also disclose aggregated user statistics in order to describe our services to current
                        and prospective business or financing partners, and to other third parties for other lawful
                        purposes.
                    </p>
                    <h4>Disclosure of information</h4>
                    <p>Information collected by us may be transferred to our corporate affiliates or our
                        subcontractors. We may share your personal information in a manner consistent with this Privacy
                        Policy, and if we or our assets are acquired or transferred in part or whole to another
                        organization, your information and any other information we have collected may be among the
                        items transferred.
                    </p>
                    <p>We may make inquiries, whether directly or through third parties, that we consider necessary to
                        verify your identity or protect you and/or us against fraud or other financial crime, and to
                        take action we reasonably deem necessary based on the results of such inquiries. You here by
                        acknowledge that we shall have the right to investigate you in case we determine you to be risky
                        or suspicious.
                    </p>
                    <p>When personal information is to be transferred in a country that has not been designated by the
                        European Commission as providing an adequate level of protection for personal information, we
                        will ensure an adequate level of protection by any of the recognized methods, including but not
                        limited to entry into the standard contractual clauses for the transfer of personal information
                        to processors established in third countries approved by the European Commission from time to
                        time.
                    </p>
                    <p>We may also disclose your personal information when we have a good faith belief that (i) we are
                        required to do so by law, or in response to a subpoena, court order, or other legal mechanism,
                        or (ii) it is necessary to: detect, prevent and address fraud and other illegal activity; to
                        protect ourselves, you and others, including as part of investigations.
                    </p>
                    <p> We do not sell your personal information to third parties.</p>
                    <p>If you are the <b>European resident</b>, and as a data subject (a person whose personal
                        information is collected, stored and processed) you have several rights under GDPR:
                    </p>
                    <p><b>Right of access.</b> You have the right to obtain confirmation if your personal information
                        is being processed by us. If that is the case, you can access your personal information and the
                        following information: (a) the purposes of the processing; (b) the categories of personal
                        information; (c) to whom the personal information has been or will be disclosed; (d) the
                        envisaged period for which the personal information will be stored, or the criteria used to
                        determine that period.
                    </p>
                    <p>If you would like to have a copy of your personal information from us, we will provide it if (1)
                        you prove your identity, (2) it will not adversely affect the rights and freedoms of others. The
                        first copy will be provided for free, for any further copies we may charge a reasonable fee
                        based on administrative costs.
                    </p>
                    <p><b>Right to rectification.</b> You have the right to demand that we correct without undue delay
                        your personal information which we have in our systems if it is inaccurate or incomplete.
                    </p>
                    <p><b>Right to erasure </b>(‘right to be forgotten’). You have the right to demand that we erase
                        your personal information, and we shall erase it without undue delay where one of the following
                        grounds applies: (a) this personal information is no longer necessary in relation to the
                        purposes for which it was processed; (b) you withdraw consent on which the processing is based,
                        and where there is no other legal ground for the processing; (c) you object to the processing
                        and there are no overriding legitimate grounds; (d) your personal information has been
                        unlawfully processed; (e) your personal information has to be erased for compliance with a legal
                        obligation.
                    </p>
                    <p><b>Right to restrict processing.</b> You have the right to restrict us in the ability of
                        processing of your information where one of the following applies: (a) you contest the accuracy
                        of your personal information and we are verifying it; (b) the processing is unlawful and you
                        want to restrict it instead of erasure; (c) we no longer need your personal information, but you
                        need it for establishment, exercise or defense of legal claims; (d) you have objected to
                        processing and we are verifying whether legitimate grounds override your request.
                    </p>
                    <p><b>Right to data portability.</b> You have the right to receive your personal information which
                        you provided to us in a structured, commonly used and machine-readable format and have the right
                        to transmit those data to another company, where: (a) the processing is based on your consent or
                        on a contract; and (b) the processing is carried out by automated means.
                    </p>
                    <p>Where technically feasible, you can demand that we transmit those data directly to another
                        company.
                    </p>
                    <p><b>Right to object.</b> You have the right to object to the processing of your personal
                        information based on our legitimate interests. We shall no longer process your personal
                        information unless we demonstrate compelling legitimate grounds for the processing or for the
                        establishment, exercise or defense of legal claims.
                    </p>
                    <p>Where personal information is processed for direct marketing purposes, you have the right to
                        object at any time to the processing of your personal information for such marketing.
                    </p>
                    <p><b>Automated individual decision-making, including profiling.</b> You have the right not to be
                        subject to a decision based solely on automated processing, including profiling, which produces
                        legal effects on you. Please note that we don’t make any automated decisions including profiling
                        based on information that we have about you.
                    </p>
                    <p><b>Right to withdraw consent.</b> You have the right to withdraw your consent for processing of
                        your personal information at any time. The withdrawal of consent shall not affect the lawfulness
                        of processing based on consent before its withdrawal.
                    </p>
                    <p><b>Right to lodge a complaint.</b> You have the right to lodge a complaint with a supervisory
                        authority,
                        in particular in the Member State of your habitual residence, place of work or place of the
                        alleged infringement if you consider that the processing of your personal information infringes
                        GDPR.
                    </p>
                    <p><b>Breach Notification.</b> If a breach/unauthorized access of personal information takes place
                        that is
                        likely to “result in a risk for the rights and freedoms of individuals”, we will notify the
                        supervisory authority within 72 hours of becoming aware of the breach.
                    </p>
                    <h4>Cookies</h4>
                    <p>As with many other websites in the world, our Sites use cookies. A cookie is a small file on your
                        device with a string of letters and numbers which serves as a unique identifier. Sometimes
                        cookies can also be used to store your personal preferences on websites.
                    </p>
                    <p>
                        When you open our Sites for the first time, cookies are created on your device. When you open it
                        next time, cookies are sent back to us, thus letting us know you’ve already been there. We don’t
                        create cookies ourselves, but we use third-party providers who do it for us.
                    </p>
                    <p>Cookies make it easier for you to use our Sites during future visits. They also allow us to
                        monitor traffic and to personalize the content of our Sites for you. Session-based cookies only
                        last while your browser is open, and are automatically deleted when you close the browser.
                        Persistent cookies last until you or your browser deletes them or until they expire.
                    </p>
                    <p> Some of our cookies are necessary for certain uses of the Sites. These cookies allow us to make
                        our Sites usable by enabling basic functions like page navigation. The Sites cannot function
                        properly without these cookies.
                    </p>
                    <p> We may also use functional cookies and cookies from third parties (Adroll) for analysis and
                        marketing purposes. Functional cookies enable certain parts of the Sites to work properly and
                        your user preferences to remain known. Analytics cookies, among other things, collect
                        information on how visitors use our Sites, the content and products that users view most
                        frequently, and the effectiveness of our third-party advertising.
                    </p>
                    <p> You can generally activate or later deactivate the use of cookies through a functionality built
                        into your web browser or mobile device. To learn more about how to control cookie settings
                        through your browser:
                    </p>
                    <table>
                        <tr>
                            <td><b>Firefox</b></td>
                            <td><a
                                href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences">Link</a>
                            </td>
                        </tr>
                        <tr>
                            <td><b>Chrome</b></td>
                            <td><a href="https://support.google.com/chrome/answer/95647?hl=en">Link</a></td>
                        </tr>
                        <tr>
                            <td><b>Internet Explorer</b></td>
                            <td><a
                                href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies">Link</a>
                            </td>
                        </tr>
                        <tr>
                            <td><b>Safari (Desktop)</b></td>
                            <td><a href="https://support.apple.com/kb/PH5042?locale=en_US">Link</a></td>
                        </tr>
                        <tr>
                            <td><b>Safari (Mobile)</b></td>
                            <td><a href="https://support.apple.com/en-us/HT201265">Link</a></td>
                        </tr>
                        <tr>
                            <td><b>Android Browser</b></td>
                            <td><a
                                href="http://support.google.com/ics/nexus/bin/answer.py?hl=en&answer=2425067">Link</a>
                            </td>
                        </tr>
                    </table>
                    <p>
                        For other browsers, please consult the documentation that your browser manufacturer provides.
                    </p>
                    <p> You can opt out of interest-based targeting provided by participating ad servers through the
                        Digital Advertising Alliance.
                    </p>
                    <p> In addition, on your iPhone, iPad or Android device, you can change your device’s privacy
                        settings to control whether you see online interest-based ads:
                    </p>
                    <table>
                        <tr>
                            <td><b>iOS device</b></td>
                            <td>
                                <b> iOS 7 or Higher</b>
                                <p>
                                    Go to your Settings > Select Privacy > Select Advertising > Enable the “Limit Ad
                                    Tracking” setting
                                </p>
                                <b>iOS 6</b>
                                <p>Go to your Settings >Select General > Select About > Select Advertising > Enable the
                                    “Limit Ads Tracking” setting
                                </p>
                                <p>
                                    More information about the iOS setting can be found <a
                                    href="http://support.apple.com/kb/ht4228">here</a>.
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td><b>Android device</b></td>
                            <td>
                                <p>Open your Google Settings app > Ads > Enable “Opt out of interest-based
                                    advertising” </p>
                            </td>
                        </tr>
                    </table>
                    <h4>Email Communications</h4>
                    <p> We may provide our registered customers with summaries of their account information and email
                        alerts. We may also allow users to subscribe to email newsletters and from time to time may
                        transmit emails promoting Looky or third-party goods or services.
                    </p>
                    <p>Subscribers have the ability to opt-out of receiving our promotional emails and to terminate
                        their newsletter subscriptions by following the instructions in the emails. Opting out in this
                        manner will not end transmission of service-related emails, such as email alerts.
                    </p>
                    <h4>Changes to Your Personal Information</h4>
                    <p>You can easily access personal information that you have submitted to us and request the
                        correction of any inaccurate information you have submitted to us via your user account.</p>
                    <p>You may also email us if you wish to deactivate your account or delete your information. We
                        will, however, need to retain your information for as long as your account is active or as long
                        as reasonably useful for commercial purposes (including if any amounts are outstanding). We will
                        retain and use your information as necessary to comply with our legal obligations, resolve
                        disputes, collect on amounts owing and enforce our agreements.
                    </p>
                    <h4>California Privacy Rights</h4>
                    <p>If you are a California resident and want to make such a request, please submit your request to
                        support@apalapp.com. In your request, please attest to the fact that you are a California resident
                        and provide a current California address for our response. Please be aware that not all
                        information sharing is covered by the California Privacy Rights requirements and only
                        information on covered sharing will be included in our response.
                    </p>
                    <h4>Changes to Privacy Policy</h4>
                    <p>This Privacy Policy may be updated from time to time. When updated the “Effective date" below
                        will be amended and the new Privacy Policy will be posted online.</p>
                    <h4>Children</h4>
                    <p>Our Site and services are not directed to individuals under 18. We do not knowingly collect
                        personal information from children under 18. If we become aware that a child under 18 has
                        provided us with personal information, we will take steps to delete such information. If you
                        become aware that a child has provided us with personal information, please contact us using
                        contacts provided on the Site.</p>
                    <h4>Contact information</h4>
                    <p>If you have any requests concerning your personal information or any queries with regard to this
                        Privacy Policy please feel free to contact us at support@apalapp.com.
                    </p>
                </Frame>
            </StyledPrivacyPolicyPage>
        </MainLayout>
    )
}

export {PrivacyPolicyPage};
