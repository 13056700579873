import styled from '@emotion/styled';
import {MEDIA_QUERIES} from "../../shared/constants/media";
import check from "../../images/check.png"

const StyledHomePage = styled.div(props => ({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    margin: '0',
    '.price-box': {
        width: '100%',
    },
    '.invite': {
        width: '54%',
        p: {
            fontFamily: 'Rubik',
            fontStyle: 'normal',
            fontWeight: 300,
            fontSize: '18px',
            lineHeight: '150%',
        },
        [MEDIA_QUERIES.w.ltMd]: {
            width: '90%',
            margin: '0 auto',
            p: {
                fontSize: '15px',
            },
        },
    },
    img: {
        width: '40%',
        marginTop: '-40px',
        maxHeight: '330px',
        zIndex: 10,
        [MEDIA_QUERIES.w.ltMd]: {
            marginTop: '5px',
            marginBottom: '15px',
            width: '100%',
        },
    },
    h2: {
        fontFamily: 'Rubik, sans-serif',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '38px',
        lineHeight: '44px',
        whiteSpace: 'pre-wrap',
        marginTop: '70px',
        marginBottom: '25px',
        color: '#000000',
        span: {
            color: 'rgba(88, 100, 205, 1)',
        },
        [MEDIA_QUERIES.w.ltMd]: {
            marginTop: '34px',
            fontSize: '28px',
            lineHeight: '36px',
        },
    },
    h3: {
        fontSize: '26px',
        color: '#252D2D',
        marginBottom: '15px',
        marginTop: '10px',
    },
    h4: {
        fontSize: '20px',
        color: '#252D2D',
        marginBottom: '10px',
        marginTop: '10px',
    },
    h5: {
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '120%',
        textAlign: 'center',
        textTransform: 'uppercase',
        color: '#5864CD',
        backgroundColor: 'rgba(88, 100, 205, 0.13)',
        marginBottom: '10px',
        marginTop: '0',
        padding: '11px 20px',
        borderRadius: '6px',
        display: 'inline-block',
    },
    button: {
        marginBottom: '10px',
        [MEDIA_QUERIES.w.ltMd]:{
          marginLeft: '20px',
        },
    },
    '.price-inf': {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '18px',
        [MEDIA_QUERIES.w.ltMd]: {
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        },
    },
    '.half-side': {
        width: '46%',
        [MEDIA_QUERIES.w.ltMd]: {
            width: '84%',
        },
    },
    '.list': {
        border: '1px solid rgba(37, 45, 45, 0.16)',
        borderRadius: '12px',
        padding: '10px 25px',
        'ul li': {
            listStylePosition: 'outside',
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '140%',
            color: 'rgba(37, 45, 45, 0.85)',
            marginBottom: '12px',
            paddingLeft: '10px',
            listStyleImage: `url(${check})`,
        },
        [MEDIA_QUERIES.w.ltMd]: {
            padding: '10px 12px',
        }
    },
    '.mom-box': {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        alignContent: 'space-between',
        [MEDIA_QUERIES.w.ltMd]: {
            width: '94%',
        },
    }
}))

const StyledBox = styled.div(props => ({
    width: '38%',
    padding: '12px 26px',
    background: '#FFFFFF',
    boxShadow: '0 2px 12px rgba(143, 173, 188, 0.08), 0 10px 18px rgba(143, 173, 188, 0.1)',
    borderRadius: '12px',
    fontFamily: 'Rubik',
    [MEDIA_QUERIES.w.ltMd]: {
        width: '100%',
        margin: '10px',
    },
    h4: {
        fontFamily: 'Rubik',
        fontWeight: 500,
        fontSize: '22px',
        color: '#252D2D',
        overflow: 'hidden',
        marginTop: '0',
        [MEDIA_QUERIES.w.ltMd]: {
            fontWeight: 500,
            fontSize: '20px',
            lineHeight: '120%',
            marginBottom: '8px',
        },
    },
    p: {
        color: 'rgba(37, 45, 45, 0.85)',
        fontWeight: 400,
        marginBottom: '4px',
        span: {
            color: 'rgba(88, 100, 205, 1)',
        }
    }
}))

export {StyledHomePage};
export {StyledBox};
