import React from "react";
import {ApolloClient, ApolloProvider, createHttpLink, InMemoryCache} from "@apollo/client";
import {setContext} from '@apollo/client/link/context';
import {Outlet, useParams} from "react-router-dom";


const httpLink = createHttpLink({
    uri: 'https://looky.apalapp.com/graphql',
});

function getClient(storeId) {
    const authLink = setContext((_, {headers}) => {
        const token = localStorage.getItem(`token-${storeId}`);
        return {
            headers: {
                ...headers,
                authorization: token ? `Bearer ${token}` : "",
            }
        }
    });

    return new ApolloClient({
        link: authLink.concat(httpLink),
        cache: new InMemoryCache()
    });
}

export function Dashboard() {
    let params = useParams();
    const client = getClient(params.storeId);

    return (
        <ApolloProvider client={client}>
            <Outlet/>
        </ApolloProvider>
    )
}

export default Dashboard;
