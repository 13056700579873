import {gql, useQuery} from "@apollo/client";
import {Navigate, useParams} from 'react-router-dom';
import {HomePage} from "./pages/HomePage/HomePage";

const CURRENT_STORE = gql`
    {
        store {
            id
            domain
            billing
            views
            clicks
        }
    }
`;

function DashboardHome() {
    let {storeId} = useParams();
    const {loading, error, data} = useQuery(CURRENT_STORE);

    const saved = localStorage.getItem(`lookyActivated-${storeId}`);
    const lookyActivated = JSON.parse(saved);

    if (loading) return <div className="loader">Loading...</div>
    if (error) return 'Error'

    if (!data.store.billing) return <Navigate to={`/dashboard/${storeId}/billing`}/>
    if (!lookyActivated) return <Navigate to={`/dashboard/${storeId}/activate-instruction`}/>

    return (
        <HomePage clicks={data.store.clicks} views={data.store.views}/>
    )
}

export {DashboardHome};
